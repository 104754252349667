import React from "react";
// @material-ui/core components

import { isEmpty, isNull } from "lodash";
import Img from 'gatsby-image/withIEPolyfill';
import ReactLoading from 'react-loading';

import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import useMediaQuery from '@material-ui/core/useMediaQuery';


// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { cardTitle } from "assets/jss/material-kit-react.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import couponPhoto from "assets/img/coupon.jpg";

import imagesStyles from "assets/jss/material-kit-react/imagesStyles.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class ExploreSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      companiesToDisplay: [],
      modalCompany: null,
      showModal: false,
      load: false,
      filter: '',
      searchValue: '',
      classicModal: false,
      openLeft: false,
      openTop: false,
      openBottom: false,
      openRight: false
    };
  }

  componentDidMount() {
    let { companies } = this.props;
    companies = this.shuffle(companies);
    this.setState({ companies, companiesToDisplay: companies, load: true });
  }

  shuffle = (array) => {
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }


  handleSearchChange = (event) => {
    event.preventDefault();
    const searchValue = event.target.value;

    this.setState((prevState, props) => {

      const searchedCompanyList = this.state.companies.filter(company =>
          company.node.title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
        );

      return {
        companiesToDisplay: searchedCompanyList,
        searchValue,
        filter: ''
      }
    });

  }

  handleButtonClick = (filter) => {
    if (this.state.filter === filter) {
      this.setState({
        companiesToDisplay: this.state.companies,
        filter: ''
      });
    } else {
      this.setState((prevState, props) => {

        const filteredCompanyList = this.state.companies.filter(company =>
          company.node.category.includes(filter)
        );

        return {
          companiesToDisplay: filteredCompanyList,
          filter,
          searchValue: ''
        }
      });
    }
  }

  showModal = (company) => {
    this.setState({ showModal: true, modalCompany: company });
  }

  hideModal = () => {
    this.setState({ showModal: false, modalCompany: null });
  }

  render() {
    const { classes } = this.props;
    const { companiesToDisplay, showModal, modalCompany } = this.state;

    let companyMapper;
    if(!isEmpty(companiesToDisplay)){
      companyMapper = companiesToDisplay.map((node, index) => {
        const company = node.node;
        return (
          <GridItem cs={12} sm={12} md={4} key={index} id={index}>
            <Card key={index} id={index}>
                <img
                  style={{ height: "180px", width: "100%", display: "block" }}
                  className={classes.imgCardTop}
                  src={company.logo.file.url}
                />
              <CardBody>
                <h4 className={classes.cardTitle}>{company.title}</h4>
                <p>
                  {company.description}
                </p>
                <Button
                  color="primary"
                  onClick={() => this.showModal(company)}
                >
                  View
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        );
      });
    } else if(!this.state.load) {
      companyMapper = (
        <React.Fragment>
          <GridItem cs={12} sm={12} md={12}>
            <h4 className={classes.title}>Grabbing all the company info, shouldn't take long!</h4>
          </GridItem>
          <ReactLoading color={'#03a550'} height={'5%'} width={'5%'} />
        </React.Fragment>
      );
    } else {
      companyMapper = (
        <React.Fragment>
          <GridItem cs={12} sm={12} md={12}>
            <h3 className={classes.title}>Oh No!</h3>
            <p className={classes.title}>
              Sorry, the company you're looking for isn't a part of The Welcome Book! <br/>
              Fill out the form below and tell us what companies you want!
            </p>
          </GridItem>
        </React.Fragment>
      )
    }

    return(
      <React.Fragment>
        <section id="explore">
          {!isNull(modalCompany) && <AdModal classes={classes} showModal={showModal} hideModal={this.hideModal} modalCompany={modalCompany} />}
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={12}>
              <h2 className={classes.title}>Explore</h2>
              <GridContainer justify="center" style={{textAlign: 'center'}}>
                <GridItem cs={12} sm={12} md={6}>
                  <img key="eat" alt="eat" className={this._getclassNames("Eat")} src={require('assets/img/buttons/eat.png')} onClick={() => this.handleButtonClick("Eat")} />
                  <img key="play" alt="play" className={this._getclassNames("Play")} src={require('assets/img/buttons/play.png')} onClick={() => this.handleButtonClick("Play")} />
                  <img key="services" alt="services" className={this._getclassNames("Services")} src={require('assets/img/buttons/services.png')} onClick={() => this.handleButtonClick("Services")} />
                  <img key="shop" alt="shop" className={this._getclassNames("Shop")} src={require('assets/img/buttons/shop.png')} onClick={() => this.handleButtonClick("Shop")} />
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem cs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Search"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.searchValue,
                      onChange: this.handleSearchChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      )
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem cs={12} sm={12} md={12}>
            </GridItem>
            {companyMapper}
          </GridContainer>
        </div>
      </section>
      </React.Fragment>
    )
  }

  /* grab badge color */
  _getclassNames(current) {
    let filter = this.state.filter;
    let className = 'filter-button';

    if(filter != '') {
      if (filter != current) {
        className += ' filter-button-gray';
      }
    }
    return className;
  }
}

const AdModal = (props) => {
  let isFullScreen = window.innerWidth <= 500;
  console.log(props.modalCompany);
  return(
    <Dialog
      fullScreen={isFullScreen}
      classes={{
        root: props.classes.center,
        paper: props.classes.modal
      }}
      open={props.showModal}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={props.classes.modalHeader}
      >
        <IconButton
          className={props.classes.modalCloseButton}
          onClick={props.hideModal}
          key="close"
          aria-label="Close"
          color="inherit"
        >
          <Close className={props.classes.modalClose} />
        </IconButton>
        <h4 className={props.classes.modalTitle}>{props.modalCompany.title}</h4>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={props.classes.modalBody}
      >
        {isFullScreen ? (
          <Img
            style={{ width: "100%", display: "block"}}
            objectFit="contain"
            fixed={props.modalCompany.ad.fixed}
          />
        ) : (
          <Img
            fixed={props.modalCompany.ad.fixed}
          />
        )}
        {props.modalCompany.url && (<a href={props.modalCompany.url} target="_blank">Visit Us</a>)}
      </DialogContent>
      <DialogActions className={props.classes.modalFooter}>
        <Button color="danger" simple onClick={props.hideModal}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default withStyles(workStyle)(ExploreSection);
